import React from "react";


function Welcome(){
    return(
    <div>
        <h3>Welcome to The Feedback App</h3><br></br>
        <p>use the navigation links to the left to access and edit your projects</p>
    </div>
    )
}

export default Welcome;